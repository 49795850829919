import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import CtaPrimary from "../Buttons/primary-cta";
import Image from "../ImageQuerys/HeroImages";
import CtaSecondary from "../Buttons/secondary-cta";
import EventCtaStyles from "./EventCtaStyles";

const { Title } = Typography;

const EventCTA = (props) => {
  const { sectionData } = props;
  return (
    <section className="event-cta">
      <Row gutter={[32, 32]}>
        <Col lg={10} sm={24}>
          <h2 className="event-cta__head">
            Come grab a drink at booth 431 <span className="blue_dot">.</span>
          </h2>
        </Col>
        <Col lg={14} sm={24}>
          <p style={{ color: "#fff" }}>
            Find SpotOn in booth 431, right in the middle of the exhibit hall.
            We’ll have a hosted bar at our booth, from 2 – 5pm on Tuesday and
            Wednesday, with a real-life demo POS station. Step in for a drink,
            talk shop with your peers, and check out the tech we have to offer
            first-hand.
          </p>
          <CtaPrimary target="/events/demo" ctaTitle="Learn more" />
        </Col>
      </Row>
      <div className="event-cta__second">
        <Row gutter={[64, 64]}>
          <Col lg={16} sm={24}>
            <Image imageName="booth.png" />
          </Col>
          <Col lg={8} sm={24}>
            <h3>
              Las Vegas Convention Center / <span>South Hall</span>
            </h3>
            <p>3150 Paradise Rd, Las Vegas, NV 89109</p>
            <CtaSecondary
              target="https://www.google.com/maps/place/Las+Vegas+Convention+Center,+3150+Paradise+Rd,+Las+Vegas,+NV+89109,+EE.+UU./@36.1311325,-115.1533079,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c46668414849:0x6a0b96708710e7ae!8m2!3d36.1311282!4d-115.1511192"
              ctaTitle="Google Maps"
              className="event-cta__maps"
              openInNewTab
            />
            <Image
              imageName="conference.png"
              className="event-cta__special-img"
            />
          </Col>
        </Row>
      </div>

      <EventCtaStyles />
    </section>
  );
};

EventCTA.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
EventCTA.defaultProps = {
  sectionData: "",
};

export default EventCTA;
