// eslint-disable-next-line import/prefer-default-export
export const showDataHero = {
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/events/demo",
  },
};

export const showDataWhiteGlove = {
  mainTitle: "Powerful tech backed by 5-star service",
  title: "Because business is personal",
  content:
    "Running a nightclub or bar business is like doing a dozen jobs at once. We’re here to help. At SpotOn, we don’t just create innovative software and point-of-sale tools for businesses like yours. We also provide you with the in-person service and support you deserve to get the most from them. Come talk to us at the Nightclub & Bar Show and see for yourself.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/events/demo",
  },
};

export const showDataLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle:
        "How to Streamline Your Operations and Improve the Guest Experience",
      blockSubTitle:
        "Catch our educational session where you’ll get first hand insight into everything you need to know about streamlining your operations and improving your guest experience with technology.",
      blockList: [
        "Kevin Bryla, \nHead of Customer Experience for SpotOn",
        "Hillary Holmes, \nGM of Von Elrod’s Beer Hall & Kitchen",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/events/demo",
      },
    },
  ],
};

export const showRotarySection = {
  title: "SpotOn across the nation",
  subtext:
    "Our team is on the road to connect with small and midsize businesses operators and learn how we can better serve you.",

  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/",
  },
  // images: [],
  images: [
    {
      image: "1-image.png",
      className: "image-wrapper--1",
      thumbnail: "1-thumbnail.png",
    },
    {
      image: "2-image.png",
      className: "image-wrapper--2",
      thumbnail: "2-thumbnail.png",
    },
    {
      image: "3-image.png",
      className: "image-wrapper--3",
      thumbnail: "3-thumbnail.png",
    },
    {
      image: "4-image.png",
      className: "image-wrapper--4",
      thumbnail: "4-thumbnail.png",
    },
    {
      image: "5-image.png",
      className: "image-wrapper--5",
      thumbnail: "5-thumbnail.png",
    },
    {
      className: "image-wrapper--6",
      thumbnail: "6-thumbnail.png",
      location: "Activmeals",
    },
  ],
};
