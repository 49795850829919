import React from "react";
import { Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  showDataHero,
  showDataWhiteGlove,
  showDataLargeFeatureA,
  showDataTrusted,
  showDataMultiSection,
} from "../../data/events/show-data";
import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
import LargeFeatures from "../../components/LargeFeatures/LargeFeatures";

import heroBg from "../../images/hero-images/nightclub-bar-event-hero.png";
import CustomSlider from "../../components/EventsComps/CustomSlider";
import RotarySection from "../../components/RotarySection";
import EventCTA from "../../components/EventsComps/EventCTA";
import EventForm from "../../components/EventsComps/EventForm";
import { rotarySection } from "../../data/rotary-section-data";

const whiteGloveImport = "event-white-glove.png";
const { Title } = Typography;

const customHero = () => {
  return (
    <>
      <Title level={2} className="hero-b__title text-center">
        Join SpotOn at the Nightclub &amp; Bar Show
        <span className="blue_dot">.</span>
        <br />
        <span
          style={{
            color: "#1769FF",
            fontSize: "38px",
            marginBottom: "24px",
            lineHeight: 1.5,
          }}
        >
          Las Vegas / June 28 - 30, 2021
        </span>
        <p
          className="text-center"
          style={{
            color: "#748093 !important",
            fontSize: 20,
            marginTop: "24px",
          }}
        >
          Find SpotOn at the{" "}
          <a
            href="https://www.ncbshow.com/"
            style={{ color: "#1769FF !important" }}
            target="_blank"
            rel="noreferrer"
          >
            Nightclub &amp; Bar Show
          </a>{" "}
          in Vegas to get an up-close look at our best-in-class point-of-sale
          and software tech designed specifically for restaurants, nightclubs,
          and bars. Or click below to get a demonstration.
        </p>
      </Title>
    </>
  );
};

const customType = {
  slides: [
    {
      bg: "kevin.png",
      cardTitle: "Kevin Bryla",
      cardContent:
        "Chief Marketing Officer & Head of Customer Experience for SpotOn",
      linkTo: "#",
    },
    // {
    //   bg: 'kevin.png',
    //   cardTitle: 'Hillary Holmes',
    //   cardContent: 'General Manager of Von Elrod’s Beer Hall & Kitchen',
    //   linkTo: '#',
    // },
  ],
};

const QuickService = () => {
  return (
    <Layout isLanding phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="Nightclub &amp; Bar Show | SpotOn | Restaurant POS and software"
        description="Join SpotOn at the Nightclub &amp; Bar Show in Las Vegas at booth 431 to grab a drink and get a behind the scenes look at our best-in-class point-of-sale and restaurant management platform built specifically for bars, nightclubs and restaurants."
        image={`https://spoton-website-staging.netlify.app/${heroBg}`}
      />
      <BHero
        sectionData={showDataHero}
        heroBg="nightclub-bar-event-hero.png"
        clearBg
        customHeroContent={customHero()}
      />
      <div style={{ marginTop: "64px" }} />
      <WhiteGlove
        sectionData={showDataWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <EventCTA />
      <LargeFeatures
        sectionData={showDataLargeFeatureA}
        // style={{ marginTop: '128px' }}
        customItem={<CustomSlider sectionData={customType} />}
      />
      <RotarySection sectionData={rotarySection} />
      <EventForm
        id="demo"
        leadType="tradeshow"
        formId="3802e416-0f35-43ee-936a-1b1cc7037886"
        thankYouPath="/events/thank-you"
      />
      {/*      <Articles
        sectionData={showDataArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default QuickService;
